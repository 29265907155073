<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-card outlined :key="formKey" id="list-forms">
              <v-card-title>Precompleted Forms</v-card-title>
              <v-card-subtitle
                >You can complete these forms and preassign settings that will
                be used when generating documentation.</v-card-subtitle
              >
              <v-card-text :key="formKey">
                <v-list
                  :key="formKey"
                  dense
                  style="height: 50vh; overflow-y: auto"
                  v-loading="loadingForms"
                  class="mt-0 pt-0"
                >
                  <v-list-item-group
                    v-model="selectedForm"
                    color="primary"
                    :key="formKey"
                  >
                    <v-list-item
                      v-for="form in forms"
                      :key="form.id"
                      :value="form"
                      @click="selectForm(form)"
                    >
                      <v-list-item-action :key="formKey">
                        {{ form.status }}
                        <v-icon
                          :key="formKey"
                          v-if="
                            formComplete(form.id)
                          "
                          color="green"
                          id="check-icon"
                          >check</v-icon
                        >
                        <!-- <v-icon
                          v-else-if="
                            formComplete(form.id) &&
                            findFormStatus(form.id) == 'Incomplete'
                          "
                          color="orange"
                          >warning</v-icon
                        > -->
                      </v-list-item-action>
                      <v-list-item-content style="max-width: 350px">
                        <v-list-item-title
                          class="text-wrap"
                          style="font-size: 12px"
                          v-html="form.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          class="text-wrap"
                          style="font-size: 12px"
                          v-html="form.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <v-card outlined>
              <v-card-text :key="formKey">
                <div v-if="selectedForm && selectedForm.name">
                  <FormModal
                    :key="formKey"
                    :form="selectedForm"
                    :skipValidate="true"
                    @submit="submitForm"
                  />
                </div>
                <div v-else class="text-center">
                  <span style="color: grey">Select a form to get started</span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </div>
</template>
<script>
import FormModal from "@/components/SystemComponents/FormModal.vue";
export default {
  props: ["item"],
  components: {
    FormModal,
  },
  data: () => ({
    loadingForms: false,
    forms: [],
    formKey: 0,
    selectedForm: {},
    showForm: false,
  }),
  watch: {
    'item.consigneeProfileFormResponses': {
      immediate: true,
      handler(){
        this.formKey++
      }
    }
    
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.getForms();
    },
    findFormStatus(formId) {
      let find = this.item.consigneeProfileFormResponses.find(
        (x) => x.formId == formId
      );
      return find ? find.status : null;
    },
    formComplete(formId) {
      let find = this.item.consigneeProfileFormResponses.find(
        (x) => x.formId == formId
      );
      return find ? true : false;
    },
    async getForms() {
      this.loadingForms = true;
      this.forms = await this.$API.getForms();
      this.loadingForms = false;
    },
    selectForm(form) {
      let find = this.item.consigneeProfileFormResponses.find(
        (x) => x.formId == form.id
      );
      this.selectedForm = form;
      if (find) {
        this.selectedForm.formResponse = find.response;
      }
      this.showForm = true;
      this.formKey++;
    },
    async submitForm(response) {
      let obj = {
        formId: this.selectedForm.id,
        consigneeProfileId: this.item.id,
        response: response,
        userId: this.$store.state.user.id,
      };
      let valid = true;
      this.selectedForm.formItems.forEach((field) => {
        let find = response.find((x) => x.key == field.value);
        if (field.required && (!find || !find.value)) {
          valid = false;
        }
      });
      obj.status = valid ? "Complete" : "Pending";
      let find = this.item.consigneeProfileFormResponses.find(
        (x) => x.formId == this.selectedForm.id
      );
      if (find) {
        obj.id = find.id;
        await this.$API.updateConsigneeProfileForm(obj);
      } else {
        if (this.item.id) {
          let result = await this.$API.createConsigneeProfileForm(obj);
          this.item.consigneeProfileFormResponses.push(result);
        } else {
          this.item.consigneeProfileFormResponses.push(obj);
        }
      }
      this.$emit('formUpdate', this.item.consigneeProfileFormResponses)
      this.$message.success("Successfully saved form");
      let findForm = this.forms.find((x) => x.id == this.selectedForm.id);
      this.selectForm(findForm)
      this.formKey++;
    },
  },
};
</script>
<style scoped>
.infoButton{
  /* position: relative; */
  right:280px
}
</style>
