<template>
  <div style="background: var(--v-component-base) !important; height: 80vh">
    <v-card flat>
      <v-card-text>
        <div v-if="activePage == 'table'">
          <v-row justify="end" class="mb-1">
            <v-col cols="12" sm="12" md="8" lg="4" class="text-right">
              <!-- <el-input
                id="profile-search"
                suffix-icon="el-icon-search"
                v-model="search"
                clearable
                placeholder="Search"
              >
                <template slot="prepend">
                  <el-button id="add-profile-button" @click="createProfile()"
                    ><i class="el-icon-plus"></i
                  ></el-button>
                </template>
              </el-input> -->

              <v-chip>
                <v-btn left small color="primary" icon id="add-profile-button" @click="createProfile()">
                    <v-icon>add_circle_outline</v-icon>
                </v-btn>
           
              <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="search"
              ></v-text-field>
            </v-chip>
            </v-col>
          </v-row>
          <v-data-table
            id="profile-info"
            style="cursor: pointer; font-size: 12px"
            :items="filteredData"
            hide-default-footer
            disable-pagination
            :loading="loading"
            :headers="headers"
            fixed-header
            height="70vh"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn-toggle
                borderless
                style="background: transparent !important"
              >
                <v-tooltip top  v-if="
                    (bookingScope && bookingScope.type == 'paid') ||
                    (shipmentScope && shipmentScope.type == 'paid')
                  ">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="copy-profile-button"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="cloneProfile(item)"
                      small
                    >
                      <v-icon small color="orange">content_copy</v-icon>
                    </v-btn>
                  </template>
                  <span style="font-size: 12px">Copy/Clone Profile</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="edit-profile-button"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="editProfile(item)"
                      small
                    >
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </template>
                  <span style="font-size: 12px">Edit Profile</span>
                </v-tooltip>
                <v-tooltip
                  top
                  v-if="
                    (bookingScope && bookingScope.type == 'paid') ||
                    (shipmentScope && shipmentScope.type == 'paid')
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="delete-profile-button"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="removeProfile(item)"
                      small
                      color="red"
                    >
                      <v-icon small color="red">delete</v-icon>
                    </v-btn>
                  </template>
                  <span style="font-size: 12px">Delete Profile</span>
                </v-tooltip>
                <!-- <v-tooltip
                  top
                  v-if="
                    shipmentScope &&
                    shipmentScope.type == 'paid' &&
                    item.status == 'Approved'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="create-file-profile-button"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="createShipmentFile(item)"
                      small
                    >
                      <v-icon small color="blue">post_add</v-icon>
                    </v-btn>
                  </template>
                  <span style="font-size: 12px">Create Shipment File</span>
                </v-tooltip> -->
                <!-- <v-tooltip top v-if="bookingScope && bookingScope.type == 'paid' && item.status == 'Approved'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="createBooking(item)" small>
                  <v-icon small color="teal">confirmation_number</v-icon>
                </v-btn>
              </template>
              <span style="font-size: 12px">Create Booking</span>
            </v-tooltip> -->
              </v-btn-toggle>
            </template>

            <template v-slot:[`item.systemReference`]="{ item }">
              <b>{{ item.systemReference }}</b>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                v-if="item.status == 'Draft'"
                color="blue"
                class="white--text"
                small
                >Draft</v-chip
              >
              <v-chip
                v-else-if="item.status == 'Approved'"
                color="success"
                class="white--text"
                small
                >Approved</v-chip
              >
              <v-chip
                v-else-if="item.status == 'Updated'"
                color="orange"
                class="white--text"
                small
                >Updated</v-chip
              >
            </template>
            <!-- <template v-slot:[`item.status`]="{ item }">
          <v-chip v-if="item.status == 'Draft'" color="blue" class="white--text" small>Draft</v-chip>
          <v-chip v-else-if="item.status == 'Approved'" color="success" class="white--text" small>Approved</v-chip>
        </template> -->
            <template v-slot:[`item.shipper.name`]="{ item }">
              <span v-if="item.shipper">{{ item.shipper.name }}</span>
              <span>
                <span v-if="item.onBehalfShipper">
                  <br />
                  On behalf of {{ item.onBehalfShipper.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.forwarder.name`]="{ item }">
              <span v-if="item.forwarder">{{ item.forwarder.name }}</span>
              <span>
                <span v-if="item.onBehalfForwarder">
                  <br />
                  On behalf of {{ item.onBehalfForwarder.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.consignee.name`]="{ item }">
              <span v-if="item.consignee">{{ item.consignee.name }}</span>
              <span>
                <span v-if="item.onBehalfConsignee">
                  <br />
                  On behalf of {{ item.onBehalfConsignee.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.buyer.name`]="{ item }">
              <span v-if="item.buyer">{{ item.buyer.name }}</span>
              <span>
                <span v-if="item.onBehalfBuyer">
                  <br />
                  On behalf of {{ item.onBehalfBuyer.name }}</span
                >
              </span>
            </template>
            <template v-slot:[`item.loadingPorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allOriginPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'loading'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.dischargePorts`]="{ item }">
              <v-chip
                color="success"
                pill
                outlined
                small
                v-if="item.allDestinationPorts"
              >
                <v-icon small left>check</v-icon> All Ports</v-chip
              >
              <div v-else>
                <span
                  v-for="(port, i) in item.consigneeProfilePorts.filter(
                    (x) => x.type == 'discharge'
                  )"
                  :key="port.id"
                >
                  <span v-if="i > 0">, </span>{{ port.name }}</span
                >
              </div>
            </template>
            <template v-slot:[`item.originCountry.name`]="{ item }">
              <v-tooltip top v-if="item.originCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="item.originCountry && item.originCountry.iso2"
                      :src="`https://cdn.loglive.io/flags/4x3/${item.originCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.originCountry.name
                }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.documentationPOD`]="{ item }">
              <!-- (1-Handler) -->
              <template v-if="item.documentationPOD">
                {{ item.documentationPOD }}
              </template>
              <template v-else>
                {{ "-" }}
              </template>
            </template>

            <template v-slot:[`item.destinationCountry.name`]="{ item }">
              <v-tooltip top v-if="item.destinationCountry">
                <template v-slot:activator="{ on }">
                  <v-avatar size="32" v-on="on">
                    <v-img
                      contain
                      v-if="
                        item.destinationCountry && item.destinationCountry.iso2
                      "
                      :src="`https://cdn.loglive.io/flags/4x3/${item.destinationCountry.iso2.toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </template>
                <span style="font-size: 12px">{{
                  item.destinationCountry.name
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.incoTerm`]="{ item }">
              <v-chip
                small
                outlined
                class="mt-1"
                v-for="term in item.consigneeProfileIncoTerms"
                :key="term.id"
                >{{ term.incoTerm }}</v-chip
              >
            </template>
          </v-data-table>
        </div>

       
        <!-- <div v-else-if="activePage == 'create'"> -->
        <v-dialog
          fullscreen
          v-model="displayConsigneeProfile"
          persistent
          transition="dialog-bottom-transition"
        >
          <v-card flat class="mx-0 px-0">
            <v-card-text class="mx-0 px-0">
              <ConsigneeProfile
                @back="setActivePage('table')"
                :contacts="contacts"
                :productKey="productKey"
                :disabledFields="disabledFields"
                :related="related"
                :countries="countries"
                :loadingProfile="itemLoading"
                @refresh="getConsigneeProfiles()"
                @refreshRelated="getRelatedOrganisations()"
                @refreshSubscribers="getConsigneeProfileSubscribers()"
                :item="item"
                :selectedCustomer="selectedCustomer"
                :auditLog="auditLog"
                @refreshRelationship="getConsigneeProfileRelationships()"
                @refreshProduct="getConsigneeProfileProducts()"
                @addCustomer="addCustomer($event)"
                @addProduct="addProduct($event)"
                @refreshCustomer="getConsigneeProfileRelationships()"
                :key="updateKey"
              />
            </v-card-text>
          </v-card>

          <!-- <ChatTest :type="'Consignee Profile'" v-show="item.id" :item="item"/> -->
        </v-dialog>
        <v-dialog v-model="incoTermDialog" persistent max-width="600">
          <v-card class="mx-auto">
            <v-card-title class="headline">Inco Terms</v-card-title>
            <v-card-text>
              <!-- <v-list dense subheader :key="dealKey">
                  <v-divider></v-divider>
                  <v-subheader style="font-size: 16px">
                    Select Deal Term
                  </v-subheader>
                  <v-divider></v-divider>
                  <v-list-item-group v-model="selectedIncoTerm">
                    <v-list-item v-for="(item) in incoTerms" :key="item.id" @click="shipment.incoTerm = item, dealKey++"
                      :style="{ 'border-left': shipment.incoTerm && shipment.incoTerm.id == item.id ? '3px solid var(--v-primary-base)' : '3px solid transparent' }">
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <b>Inco Term:</b> {{ item.incoTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <b>Shipping Line: </b>{{ item.shippingLine }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <b>Payment term:</b> {{ item.paymentTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <b>Freight Charge:</b> {{ item.freightCharge ? item.freightCharge.toUpperCase() : null }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="item.freightCharge == 'forwarder'">
                          <b>Freight Charge To:</b> {{ item.freightInvoiceTo ? item.freightInvoiceTo.toUpperCase() : null
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <b>Origin Charge:</b> {{ item.originCharge ? item.originCharge.toUpperCase() : null }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="item.originCharge == 'forwarder'">
                          <b>Origin Charge To:</b> {{ item.originInvoiceTo ? item.originInvoiceTo.toUpperCase() : null }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <b>Destination Charge: </b>{{ item.destinationCharge ? item.destinationCharge.toUpperCase() :
                            null
                          }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="item.destinationCharge == 'forwarder'">
                          <b>Destination Charge To: </b>{{ item.destinationInvoiceTo ?
                            item.destinationInvoiceTo.toUpperCase() : null }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <b>Contract Owner:</b> {{ item.contractOwner ? item.contractOwner.toUpperCase() : null }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <b>Comment:</b> {{ item.comment }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item v-if="incoTerms.length == 0">
                      <v-list-item-content>
                        <span>No deal terms available</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>

                </v-list> -->
              <v-list>
                <v-list-item-group v-model="selectedIncoTerm">
                  <v-list-item
                    v-for="(item, i) in incoTerms"
                    :key="i"
                    :value="item"
                    active-color="primary"
                  >
                    <v-list-item-content>
                      <v-list-item-subtitle>
                        <b>Inco Term:</b> {{ item.incoTerm }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Shipping Line: </b>{{ item.shippingLine }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Payment term:</b> {{ item.paymentTerm }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Freight Charge:</b>
                        {{
                          item.freightCharge
                            ? item.freightCharge.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="item.freightCharge == 'forwarder'"
                      >
                        <b>Freight Charge To:</b>
                        {{
                          item.freightInvoiceTo
                            ? item.freightInvoiceTo.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Origin Charge:</b>
                        {{
                          item.originCharge
                            ? item.originCharge.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="item.originCharge == 'forwarder'"
                      >
                        <b>Origin Charge To:</b>
                        {{
                          item.originInvoiceTo
                            ? item.originInvoiceTo.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Destination Charge: </b
                        >{{
                          item.destinationCharge
                            ? item.destinationCharge.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="item.destinationCharge == 'forwarder'"
                      >
                        <b>Destination Charge To: </b
                        >{{
                          item.destinationInvoiceTo
                            ? item.destinationInvoiceTo.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Contract Owner:</b>
                        {{
                          item.contractOwner
                            ? item.contractOwner.toUpperCase()
                            : null
                        }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <b>Comment:</b> {{ item.comment }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="incoTermDialog = false"
                >Cancel</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="saveShipment()"
                :disabled="!selectedIncoTerm"
                >Create</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import ConsigneeProfile from "../Customer/ConsigneeProfile/ManageProfile.vue";
import ConsigneeProfile from "./ShipmentProfileComponents/ManageProfile.vue";
export default {
  props: ["selectedCustomer", "bookingScope", "shipmentScope"],
  components: {
    // ChatTest,
    // BookingModal,
    ConsigneeProfile,
  },
  data: () => ({
    activePage: "table",
    addresses: [],
    auditLog: [],
    bookingModal: false,
    bookingItem: {},
    countries: [],
    contacts: [],
    incoTerms: [],
    createdShipments: [],
    createdShipmentsComplete: false,
    disabledFields: [],
    displayConsigneeProfile: false,
    expanded: [],
    filePage: 1,
    fileParams: {
      limit: 10,
      offset: 0,
      search: null,
      origin: null,
      destination: null,
    },
    fileTimeout: null,
    filterDialog: false,
    intraBooking: false,
    incoTermDialog: false,
    selectedIncoTerm: null,
    headers: [
      //  {
      //     text: '',
      //     value: 'data-table-expand'
      // },
      {
        text: "Action",
        value: "action",
        align: "center",
        width: "150px",
        sortable: false,
      },
      {
        text: "System Ref",
        value: "systemReference",
        align: "left",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },

      {
        text: "Reference",
        value: "reference",
        align: "center",
      },
      {
        text: "Consignee",
        value: "consignee.name",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipper.name",
        align: "center",
      },
      // {
      //   text: "Buyer",
      //   value: "buyer.name",
      //   align: "center",
      // },

      {
        text: "Forwarder",
        value: "forwarder.name",
        align: "center",
      },
      {
        text: "Origin",
        value: "originCountry.name",
        sortable: false,
        align: "center",
      },
      {
        text: "Loading Ports",
        value: "loadingPorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Destination",
        value: "destinationCountry.name",
        sortable: false,
        align: "center",
      },

      {
        text: "Discharge Ports",
        value: "dischargePorts",
        sortable: false,
        align: "center",
      },
      {
        text: "Doc POD",
        value: "documentationPOD",
        sortable: false,
        align: "center",
        documentName: null,
      },
      {
        text: "Inco Term(s)",
        value: "incoTerm",
        sortable: false,
        align: "center",
      },
      // {
      //   text: "Currency",
      //   value: "currencyCode",
      //   sortable: false,
      //   align: "center",
      // },
    ],
    selectedShipments: [],
    sortBy: "consignee.name",
    sortDesc: false,
    shipmentHeaders: [
      // {
      //   text: "ID",
      //   value: "id",
      //   align: "center",
      //   visible: false,
      // },
      {
        text: "File Number",
        value: "fileNumber",
        align: "center",
      },
      {
        text: "Linked Bookings",
        value: "noOfBookings",
        align: "center",
      },
      {
        text: "No. Containers",
        value: "noOfContainers",
        align: "center",
      },
      {
        text: "Shipper",
        value: "shipperName",
        align: "center",
      },
      {
        text: "Receiver",
        value: "receiverName",
        align: "center",
      },
      {
        text: "Vessel/Voyage",
        value: "vessel",
        align: "center",
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        align: "center",
      },
      // {
      //   text: "POD",
      //   value: "portOfDischargeCity",
      //   align: "center",
      // },
      {
        text: "Country of Destination",
        value: "countryOfDestination",
        align: "center",
      },
    ],
    podName: null,
    item: {},
    itemLoading: false,
    loadingText: "Creating shipment",
    loading: false,
    loadingFiles: false,
    params: {},
    profiles: {
      total: 0,
      data: [],
    },
    related: [],
    search: "",
    savingShipment: false,
    shipmentFileDialog: false,
    shipmentFiles: {
      total: 0,
      data: [],
    },
    shipment: {},
    sortFileBy: "id",
    sortFileDesc: true,
    userAddress: null,
    productKey: 7000,
    updateKey: 6000,
    dealKey: 0,
    createShipmentGuideStart: false,
  }),
  watch: {
    activePage(val) {
      this.displayConsigneeProfile = val == "create";
    },
    "selectedCustomer.id": {
      immediate: true,
      handler(value) {
        if (value) {
          this.getConsigneeProfiles();
          this.getRelatedOrganisations();
        }
      },
    },
    async filePage(value) {
      this.fileParams.offset = (value - 1) * this.fileParams.limit;
      await this.getShipmentFiles();
    },
    "fileParams.limit": {
      immediate: true,
      async handler() {
        await this.getShipmentFiles();
      },
    },
    "fileParams.search": {
      immediate: true,
      async handler() {
        await this.getShipmentFiles();
      },
    },
    sortFileBy(value) {
      this.fileParams.sortBy = value;
      this.getShipmentFiles();
    },
    sortFileDesc(value) {
      this.fileParams.sortDesc = value;
      this.getShipmentFiles();
    },
    selectedIncoTerm: {
      immediate: true,
      handler() {
        console.log(this.selectedIncoTerm);
        if (this.selectedIncoTerm && this.selectedIncoTerm != null) {
          this.shipment.incoTermId = this.selectedIncoTerm.id;
        } else {
          this.shipment.incoTermId = null;
        }
      },
    },
  },
  computed: {
    filteredData() {
      let result = this.profiles.data;
      if (this.search) {
        result = result.filter(
          (item) =>
            (item.systemReference &&
              item.systemReference
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.reference &&
              item.reference
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.buyer &&
              item.buyer.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.forwarder &&
              item.forwarder.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.shipper &&
              item.shipper.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.consignee &&
              item.consignee.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.onBehalfForwarder &&
              item.onBehalfForwarder.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.onBehalfShipper &&
              item.onBehalfShipper.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.onBehalfConsignee &&
              item.onBehalfConsignee.name
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (item.consigneeProfileIncoTerms &&
              item.consigneeProfileIncoTerms
                .map((y) => y.incoTerm.toLowerCase())
                .includes(this.search.toLowerCase()))
        );
      }
      return result;
    },
  },
  created() {
    this.loadCountries();
    this.getOrganisationAddresses();
    this.getContacts();
  },
  methods: {
    calculateContainers(bookings) {
      return bookings
        .map((item) => item.bookingContainers.length)
        .reduce((prev, next) => prev + next);
    },
    createBooking(item) {
      this.bookingItem = item;
      this.intraBooking = true;
      // this.bookingModal = true
    },
    redirectToBooking(type) {
      this.$router.push({
        path: "/bookings/create/" + this.selectedCustomer.relatedOrganisationId,
        query: { profile: this.bookingItem.systemReference, type: type },
      });
    },
    createProfile() {
      console.log(this.selectedCustomer);

      this.auditLog = [];
      this.itemLoading = false;
      this.item = {
        movementType: "EXPORT",
        type: "LOGISTICS",
        modeOfTransport: "OCEAN",
        serviceType: "FCL",
        editable: true,
        preAdviseRequired: false,
        bookingContainerItems: [],
        consigneeProfileDocuments: [],
        consigneeProfileFormResponses: [],
        consigneeProfilePorts: [],
        consigneeProfileIncoTerms: [],
        consigneeProfileShippingContracts: [],
        consigneeProfileDistributionLists: [],
        consigneeProfileProducts: [],
        consigneeProfileRelationships: [
          {
            customerId: this.selectedCustomer.relatedOrganisationId,
            organisationRelationshipId: this.selectedCustomer.id,
          },
        ],
        notificationSubscribers: {
          users: [],
          teams: this.selectedCustomer.relationshipTeams.map((x) => ({
            teamId: x.teamId,
            team: x.team,
          })),
        },
        modalType: "Add",
        allOriginPorts: true,
        allDestinationPorts: true,
        incoTerm: "EXW",
        originCountry: {
          continent: "Africa",
          id: 343,
          iso2: "ZA",
          name: "South Africa",
        },
        originCountryId: 343,
        currencyCode: "USD",
        status: "Draft",
      };

      let keys = [
        "buyer",
        "shipper",
        "forwarder",
        "consignee",
        "firstNotifyParty",
        "secondNotifyParty",
        "courierParty",
      ];

      keys.forEach((x) => {
        if (this.selectedCustomer[x]) {
          this.item[x + "Id"] = this.selectedCustomer.relatedOrganisationId;
          this.item[x] = this.selectedCustomer.relatedOrganisation;
          if (
            this.selectedCustomer.relatedOrganisation.organisationAddresses &&
            this.selectedCustomer.relatedOrganisation.organisationAddresses
              .length > 0
          ) {
            let address =
              this.selectedCustomer.relatedOrganisation.organisationAddresses.find(
                (y) => y.isDocsAddress
              );
            if (address) {
              this.item[x + "Address"] = address;
              this.item[x + "AddressId"] = address.id;
            } else {
              address =
                this.selectedCustomer.relatedOrganisation.organisationAddresses.find(
                  (y) => y.isHQ
                );
              if (address) {
                this.item[x + "Address"] = address;
                this.item[x + "AddressId"] = address.id;
              } else {
                address =
                  this.selectedCustomer.relatedOrganisation
                    .organisationAddresses[0];
                this.item[x + "Address"] = address;
                this.item[x + "AddressId"] = address.id;
              }
            }

            // this.item[x + "Address"] =
            //   this.selectedCustomer.relatedOrganisation.organisationAddresses[0];
            // this.item[x + "AddressId"] =
            //   this.selectedCustomer.relatedOrganisation.organisationAddresses[0].id;
          }
        } else if (this.selectedCustomer.inverse[x]) {
          this.item[x + "Id"] = this.selectedCustomer.organisationId;
          this.item[x] = this.selectedCustomer.organisation;
          if (
            this.selectedCustomer.organisation.organisationAddresses &&
            this.selectedCustomer.organisation.organisationAddresses.length > 0
          ) {
            let address =
              this.selectedCustomer.organisation.organisationAddresses.find(
                (y) => y.isDocsAddress
              );
            if (address) {
              this.item[x + "Address"] = address;
              this.item[x + "AddressId"] = address.id;
            } else {
              address =
                this.selectedCustomer.organisation.organisationAddresses.find(
                  (y) => y.isHQ
                );
              if (address) {
                this.item[x + "Address"] = address;
                this.item[x + "AddressId"] = address.id;
              } else {
                address =
                  this.selectedCustomer.organisation.organisationAddresses[0];
                this.item[x + "Address"] = address;
                this.item[x + "AddressId"] = address.id;
              }
            }

            // this.item[x + "Address"] =
            //   this.selectedCustomer.organisation.organisationAddresses[0];
            // this.item[x + "AddressId"] =
            //   this.selectedCustomer.organisation.organisationAddresses[0].id;
          }
        }
      });
      console.log(this.item);
      this.setActivePage("create");
    },
    createShipment(item) {
      this.item = item;
      this.setActivePage("booking");
    },
    createShipmentFile(item) {
      if (item.consigneeProfileIncoTerms.length == 0) {
        this.$message.error(
          "Please add Inco Terms to the profile before creating a shipment"
        );
        return;
      }
      this.createdShipmentsComplete = false;
      this.shipment = {
        consigneeProfileId: item.id,
        documentProcessingAddressId: this.userAddress
          ? this.userAddress.organisationAddressId
          : null,
      };

      if (!this.shipment.documentProcessingAddressId) {
        this.shipment.documentProcessingAddressId = this.addresses.find(
          (x) => x.isHQ
        )
          ? this.addresses.find((x) => x.isHQ).id
          : null;
      }
      this.createdShipments = [];
      this.selectedShipments = [];
      this.shipmentFileDialog = true;
      this.fileParams.destination = item.destinationCountry.iso2;
      this.fileParams.origin = item.originCountry.iso2;
      this.fileParams.shipperId = item.shipperId;
      this.getShipmentFiles();
      this.getShipmentIncoTerms(item.id);
    },
    async getShipmentIncoTerms(profileId) {
      this.incoTerms = await this.$API.getConsigneeProfileIncoTerms(profileId);
    },
    async cloneProfile(item) {
      this.itemLoading = true;
      this.$emit("startLoad");

      this.setActivePage("create");
      this.item = await this.$API.getConsigneeProfile(item.id);
      // this.item.consigneeProfileShippingContracts =
      //   await this.$API.getConsigneeProfileContracts(item.id);
      this.item.consigneeProfileDocuments =
        await this.$API.getConsigneeProfileDocuments(item.id);
      this.item.consigneeProfileRelationships =
        await this.$API.getConsigneeProfileRelationships(item.id);
        this.item.consigneeProfileShares =
        await this.$API.getConsigneeProfileShareSetting(item.id);
      this.item.consigneeProfileProducts =
        await this.$API.getConsigneeProfileProducts(item.id);
      this.itemLoading = false;
      this.item.modalType = "Add";
      this.item.status = "Draft";
      this.item.editable = true;
      if (this.item.reference) {
        this.item.reference = this.item.reference + "/1";
      }
      delete this.item.id;
      delete this.item.systemReference;
      this.item.consigneeProfileShares.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
        delete x.createdAt
        delete x.updatedAt
      });
      this.item.consigneeProfileFormResponses.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.item.consigneeProfileRelationships.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.item.bookingContainerItems.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
        x.bookingCargoItems.forEach((y) => {
          delete y.id;
          delete y.consigneeProfileId;
        });
      });
      this.item.consigneeProfileDocuments.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.item.consigneeProfilePorts.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.item.consigneeProfileIncoTerms.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
        x.consigneeProfileShippingContracts.forEach((y) => {
          delete y.id;
          delete y.consigneeProfileIncoTermId;
        });
      });
      this.item.consigneeProfileDistributionLists.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.item.consigneeProfileProducts.forEach((x) => {
        delete x.id;
        delete x.consigneeProfileId;
      });
      this.setActivePage("create");
      this.$emit("stopLoad");
    },
    expandRow(data) {
      let find = this.expanded.find((x) => x.id == data.id);
      if (!find) this.expanded = [data];
      else this.expanded = [];
    },

    async editProfile(item) {
      this.itemLoading = true;
      this.$emit("startLoad");
      this.setActivePage("create");
      this.item = await this.$API.getConsigneeProfile(item.id);
      this.item.modalType = "Edit";
      // this.item.consigneeProfileShippingContracts =
      //   await this.$API.getConsigneeProfileContracts(item.id);
      this.item.consigneeProfileDocuments =
        await this.$API.getConsigneeProfileDocuments(item.id);
      this.item.notificationSubscribers =
        await this.$API.getConsigneeProfileSubscribers(item.id);
      this.item.consigneeProfileRelationships =
        await this.$API.getConsigneeProfileRelationships(item.id);
      this.item.consigneeProfileShares = await this.$API.getConsigneeProfileShareSetting(
        item.id
      );
        await this.$API.getConsigneeProfileProducts(item.id);
      this.disabledFields = this.selectedCustomer.networkFunctions;
      let findShare = this.item.consigneeProfileShares.find(x => x.sharedPartyId == this.$store.state.currentOrg.id)
      findShare = findShare ? findShare.share: false;
      this.item.editable = (this.item.ownerId == this.$store.state.currentOrg.id) || findShare
      console.log("Editable", this.item.editable);
      this.itemLoading = false;
      this.$emit("stopLoad");
    },
    async getContacts() {
      let data = await this.$API.getRelation({});
      this.contacts = data.data
    },
    async getConsigneeProfiles() {
      console.log("Refreshing");
      this.loading = true;
      this.profiles = await this.$API.listCustomerConsigneeProfile(
        this.selectedCustomer.relatedOrganisationId,
        {}
      );
      this.loading = false;
      console.log("profiles", this.profiles);
    },
    addCustomer(item) {
      this.item.consigneeProfileRelationships.push(item);
    },
    addProduct(item) {
      this.item.consigneeProfileProducts.push(item);
      this.productKey++;
    },
    async getRelatedOrganisations() {
      this.related = await this.$API.getCustomerRelationships(
        this.selectedCustomer.relatedOrganisationId
      );
      this.related.forEach((x) => {
        if (x.relatedOrganisation.primaryOrganisationId) {
          let find = this.related.find(
            (y) =>
              y.relatedOrganisationId ==
              x.relatedOrganisation.primaryOrganisationId
          );
          x.buyer = find.buyer;
          x.producer = find.producer;
          x.shipper = find.shipper;
          x.consignee = find.consignee;
          x.coldstore = find.coldstore;
          x.notifyParty = find.notifyParty;
        }
      });
    },
    async getOrganisationAddresses() {
      this.addresses = await this.$API.getOrganisationAddress();
      this.userAddress = await this.$API.getOrganisationUserAddress();
    },
    async getConsigneeProfileSubscribers() {
      console.log("Refresh subs", this.item.notificationSubscribers);
      this.item.notificationSubscribers =
        await this.$API.getConsigneeProfileSubscribers(this.item.id);
    },
    async getShipmentFiles() {
      if (this.fileTimeout) {
        clearTimeout(this.fileTimeout);
      }
      this.loadingFiles = true;
      this.fileTimeout = setTimeout(async () => {
        this.shipmentFiles = await this.$API.listShipmentFile({
          params: this.fileParams,
        });
        this.loadingFiles = false;
      }, 500);
    },
    async getConsigneeProfileRelationships() {
      if (this.item.id) {
        this.item.consigneeProfileRelationships =
          await this.$API.getConsigneeProfileRelationships(this.item.id);
      }
      this.updateKey++;
    },
    async getConsigneeProfileShareSetting() {
      if (this.item.id) {
        this.item.consigneeProfileShares =
          await this.$API.getConsigneeProfileShareSetting(this.item.id);
      }
      this.updateKey++;
    },
    async getConsigneeProfileProducts() {
      if (this.item.id) {
        this.item.consigneeProfileProducts =
          await this.$API.getConsigneeProfileProducts(this.item.id);
      }
      this.productKey++;
    },
    async loadCountries() {
      this.countries = await this.$API.searchCountriesBasic({});
    },
    async removeProfile(item) {
      this.$confirm(
        "Are you sure you want to delete this consignee profile?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateConsigneeProfile({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.getConsigneeProfiles();
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    async checkIncoTerm(item) {
      if (this.incoTerms.length > 1) {
        this.incoTermDialog = true;
      } else if (this.incoTerms.length === 1) {
        this.shipment.incoTermId = this.incoTerms[0].id;
        this.saveShipment();
      }
    },
    async saveShipment() {
      this.incoTermDialog = false;
      this.createdShipments = [];
      for (let i = 0; i < this.selectedShipments.length; i++) {
        this.loadingText =
          "Creating shipment " + (i + 1) + "/" + this.selectedShipments.length;
        let obj = this.shipment;
        obj.shipmentFileId = this.selectedShipments[i].id;
        this.savingShipment = true;
        let findShipment = await this.$API.checkShipmentFileExist(
          obj.shipmentFileId
        );
        let result;
        if (findShipment) {
          result = await this.$API.createShipment(obj);
          this.createdShipments.push(result);
        } else {
          this.$message.error("Shipment already exists");
        }
        console.log(result);
      }
      this.selectedShipments = [];
      this.createdShipmentsComplete = true;
      this.savingShipment = false;
    },
    routeToShipment(item) {
      this.$router.push({
        path: "/documentation/" + item.shipmentReference,
      });
    },
    setActivePage(page) {
      this.$emit("summary");
      this.activePage = page;
      if (page != "table") {
        this.$emit("hideTabs");
      } else {
        this.$emit("showTabs");
      }
    },
    createShipmentModal() {
      if (this.createShipmentGuideStart) {
        this.createShipmentGuideStart = false;
      } else {
        this.createShipmentGuideStart = true;
      }
    },
  },
};
</script>
