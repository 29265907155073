<template>
  <div>
    <v-card
      outlined
      id="deal-terms"
      class="infoCards"
      style="background-color: var(--v-toolbar-lighten1) !important"
    >
      <v-card-text class="my-0">
        <v-list dense subheader>
          <v-subheader style="font-size: 14px">
            <v-icon class="mr-2" small>payments</v-icon>
            Freight Deal Terms
            <v-btn
              v-if="profile.editable"
              icon
              color="primary"
              @click="addPayment({})"
              id="add-deal-terms"
              ><v-icon>add_circle_outline</v-icon></v-btn
            ></v-subheader
          >
          <v-divider></v-divider>
          <v-list-item v-if="profile.consigneeProfileIncoTerms.length == 0">
            <v-list-item-content>
              <span style="color: grey">No Deal Terms listed</span>
            </v-list-item-content>
          </v-list-item>
          <v-card
            class="my-2 py-2"
            flat
            style="background-color: var(--v-greyRaised-base) !important"
            v-for="(itemProfile, index) in profile.consigneeProfileIncoTerms"
            :key="index"
          >
            <v-toolbar flat dense color="transparent">
              <v-toolbar-title>
                <v-chip>{{ index + 1 }}</v-chip>
                <span class="ml-2" style="font-size: 14px; font-weight: 500">{{
                  itemProfile.incoTerm
                }}</span>
                <v-chip
                  class="ml-2"
                  outlined
                  style="border: none"
                  v-if="itemProfile.contractParty"
                  small
                >
                  <v-avatar
                    left
                    :color="
                      itemProfile.contractParty &&
                      itemProfile.contractParty.logo
                        ? 'white'
                        : 'secondary'
                    "
                    size="32"
                  >
                    <v-img
                      v-if="
                        itemProfile.contractParty &&
                        itemProfile.contractParty.logo
                      "
                      :src="itemProfile.contractParty.logo"
                      contain
                    />
                    <h3 v-else-if="itemProfile.contractParty">
                      {{ itemProfile.contractParty.name.charAt(0) }}
                    </h3>
                    <h3 v-else>-</h3>
                  </v-avatar>
                  <span
                    v-if="itemProfile.contractParty.alias"
                    style="font-size: 12px; font-weight: 500"
                    >{{ itemProfile.contractParty.alias }}</span
                  >
                  <span v-else style="font-size: 12px; font-weight: 500">{{
                    itemProfile.contractParty.name
                  }}</span>
                </v-chip>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                v-if="profile.editable"
                icon
                small
                @click="editPayment(itemProfile)"
                id="edit-deal-terms"
                ><v-icon small>edit</v-icon></v-btn
              >
              <v-btn
                v-if="profile.editable"
                icon
                small
                color="red"
                @click="removeProfile(itemProfile)"
                id="delete-deal-terms"
                ><v-icon small>delete</v-icon></v-btn
              >
            </v-toolbar>
            <v-card-text class="my-0 py-0">
              <v-row class="pa-1">
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Details</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            itemProfile.contractParty &&
                            itemProfile.contractParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              itemProfile.contractParty &&
                              itemProfile.contractParty.logo
                            "
                            :src="itemProfile.contractParty.logo"
                            contain
                          />
                          <h3 v-else-if="itemProfile.contractParty">
                            {{ itemProfile.contractParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="itemProfile.contractParty"
                        >
                          <span
                            v-if="itemProfile.contractParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.contractParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.contractParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Contract Owner
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item style="height: 30px">
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                        >
                          {{ itemProfile.incoTerm }}
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          INCO Term
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          class="text-wrap"
                          v-if="itemProfile.comment"
                        >
                          {{ itemProfile.comment }}
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 12px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          Notes
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col cols="12" md="6">
                  <v-list dense subheader class="my-0 py-0">
                    <v-subheader style="font-size: 14px">Charges</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            itemProfile.originParty &&
                            itemProfile.originParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              itemProfile.originParty &&
                              itemProfile.originParty.logo
                            "
                            :src="itemProfile.originParty.logo"
                            contain
                          />
                          <h3 v-else-if="itemProfile.originParty">
                            {{ itemProfile.originParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="itemProfile.originParty"
                        >
                          <span
                            v-if="itemProfile.originParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.originParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.originParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ itemProfile.originTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Origin Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            itemProfile.freightParty &&
                            itemProfile.freightParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              itemProfile.freightParty &&
                              itemProfile.freightParty.logo
                            "
                            :src="itemProfile.freightParty.logo"
                            contain
                          />
                          <h3 v-else-if="itemProfile.freightParty">
                            {{ itemProfile.freightParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="itemProfile.freightParty"
                        >
                          <span
                            v-if="itemProfile.freightParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.freightParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.freightParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ itemProfile.paymentTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Freight Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-action class="mr-1">
                        <v-avatar
                          size="32"
                          :color="
                            itemProfile.destinationParty &&
                            itemProfile.destinationParty.logo
                              ? 'white'
                              : 'secondary'
                          "
                        >
                          <v-img
                            v-if="
                              itemProfile.destinationParty &&
                              itemProfile.destinationParty.logo
                            "
                            :src="itemProfile.destinationParty.logo"
                            contain
                          />
                          <h3 v-else-if="itemProfile.destinationParty">
                            {{ itemProfile.destinationParty.name.charAt(0) }}
                          </h3>
                          <h3 v-else>-</h3>
                        </v-avatar>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-if="itemProfile.destinationParty"
                        >
                          <span
                            v-if="itemProfile.destinationParty.alias"
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.destinationParty.alias }}</span
                          >
                          <span
                            v-else
                            style="font-size: 12px; font-weight: 500"
                            >{{ itemProfile.destinationParty.name }}</span
                          >
                        </v-list-item-title>
                        <v-list-item-title
                          style="text-transform: capitalize; font-size: 14px"
                          v-else
                        >
                          -
                        </v-list-item-title>
                        <v-list-item-subtitle style="font-size: 11px">
                          {{ itemProfile.destinationTerm }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size: 11px">
                          Destination Charges
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-list>
      </v-card-text>
    </v-card>

    <!-- Inco Term Dialog -->
    <v-dialog
      v-model="incoTermDialog"
      scrollable
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-card-title style="font-size: 16px"
          >Setup Terms <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :loading="savingIncoTerm"
            :disabled="
              !incoTerm.incoTerm ||
              !incoTerm.paymentTerm ||
              !incoTerm.originTerm ||
              !incoTerm.destinationTerm ||
              !incoTerm.originPartyId ||
              !incoTerm.freightPartyId ||
              !incoTerm.destinationPartyId ||
              !incoTerm.contractPartyId
            "
            @click="saveIncoTerm()"
          >
            <v-icon small>save</v-icon>
          </v-btn>
          <v-btn
            icon
            color="redPop"
            @click="(incoTermDialog = false), (incoTerm = {})"
            ><v-icon small>close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-list dense subheader>
                <v-subheader style="font-size: 14px">Details</v-subheader>
                <v-select
                  dense
                  outlined
                  label="Inco Term*"
                  v-model="incoTerm.incoTerm"
                  :items="incoTerms"
                  item-text="abbreviation"
                  item-value="abbreviation"
                  hide-details
                  class="my-2"
                >
                  <template v-slot:label>
                    <span>INCO Term</span>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        data.item.abbreviation
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
                <v-list-item
                  style="height: 30px"
                  @click="editChargeParty('contract')"
                >
                  <v-list-item-action>
                    <v-avatar
                      :color="
                        incoTerm.contractParty && incoTerm.contractParty.logo
                          ? 'white'
                          : 'secondary'
                      "
                      size="32"
                    >
                      <v-img
                        v-if="
                          incoTerm.contractParty && incoTerm.contractParty.logo
                        "
                        :src="incoTerm.contractParty.logo"
                        contain
                      />
                      <h3 v-else-if="incoTerm.contractParty">
                        {{ incoTerm.contractParty.name.charAt(0) }}
                      </h3>
                      <h3 v-else>-</h3>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="incoTerm.contractParty">{{
                      incoTerm.contractParty.name
                    }}</v-list-item-title>
                    <v-list-item-title v-else> - </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 11px"
                      >Contract Owner</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-textarea v-model="incoTerm.comment" outlined rows="5">
                  <template v-slot:label>
                    <span>Notes</span>
                  </template>
                </v-textarea>
              </v-list>
            </v-col>
            <v-col cols="12" md="3">
              <v-list dense subheader>
                <v-subheader style="font-size: 14px">Origin</v-subheader>
                <v-select
                  v-model="incoTerm.originTerm"
                  outlined
                  dense
                  label="Payment Term*"
                  item-text="value"
                  item-value="key"
                  :items="paymentTerms"
                  hide-details
                  class="my-2"
                >
                  <template v-slot:label>
                    <span>Payment Term*</span>
                  </template>
                </v-select>
                <v-list-item
                  style="height: 30px"
                  @click="editChargeParty('origin')"
                >
                  <v-list-item-action>
                    <v-avatar
                      :color="
                        incoTerm.originParty && incoTerm.originParty.logo
                          ? 'white'
                          : 'secondary'
                      "
                      size="32"
                    >
                      <v-img
                        v-if="incoTerm.originParty && incoTerm.originParty.logo"
                        :src="incoTerm.originParty.logo"
                        contain
                      />
                      <h3 v-else-if="incoTerm.originParty">
                        {{ incoTerm.originParty.name.charAt(0) }}
                      </h3>
                      <h3 v-else>-</h3>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="incoTerm.originParty">{{
                      incoTerm.originParty.name
                    }}</v-list-item-title>
                    <v-list-item-title v-else> - </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 11px"
                      >Origin Charges</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="12" md="3">
              <v-list dense subheader>
                <v-subheader style="font-size: 14px">Freight</v-subheader>
                <v-select
                  v-model="incoTerm.paymentTerm"
                  outlined
                  dense
                  label="Payment Term*"
                  item-text="value"
                  item-value="key"
                  :items="paymentTerms"
                  hide-details
                  class="my-2"
                >
                  <template v-slot:label>
                    <span>Payment Term*</span>
                  </template>
                </v-select>
                <v-list-item
                  style="height: 30px"
                  @click="editChargeParty('freight')"
                >
                  <v-list-item-action>
                    <v-avatar
                      :color="
                        incoTerm.freightParty && incoTerm.freightParty.logo
                          ? 'white'
                          : 'secondary'
                      "
                      size="32"
                    >
                      <v-img
                        v-if="
                          incoTerm.freightParty && incoTerm.freightParty.logo
                        "
                        :src="incoTerm.freightParty.logo"
                        contain
                      />
                      <h3 v-else-if="incoTerm.freightParty">
                        {{ incoTerm.freightParty.name.charAt(0) }}
                      </h3>
                      <h3 v-else>-</h3>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="incoTerm.freightParty">{{
                      incoTerm.freightParty.name
                    }}</v-list-item-title>
                    <v-list-item-title v-else> - </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 11px"
                      >Freight Charges</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-select
                  clearable
                  outlined
                  dense
                  label="Forwarder Invoice Freight Charge To"
                  :items="chargeOptions"
                  v-if="incoTerm.freightCharge == 'forwarder'"
                  v-model="incoTerm.freightInvoiceTo"
                  item-text="friendlyName"
                  item-value="value"
                  style="text-transform: capitalize"
                  hide-details
                  class="my-2"
                  ref="freightInvoice"
                >
                  <template v-slot:label>
                    <span>Invoice Freight Charge To*</span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.friendlyName
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.name
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select> -->
              </v-list>
            </v-col>
            <v-col cols="12" md="3">
              <v-list dense subheader>
                <v-subheader style="font-size: 14px">Destination</v-subheader>
                <v-select
                  v-model="incoTerm.destinationTerm"
                  outlined
                  dense
                  label="Payment Term*"
                  item-text="value"
                  item-value="key"
                  hide-details
                  class="my-2"
                  :items="paymentTerms"
                >
                  <template v-slot:label>
                    <span>Payment Terms*</span>
                  </template>
                </v-select>

                <v-list-item
                  style="height: 30px"
                  @click="editChargeParty('destination')"
                >
                  <v-list-item-action>
                    <v-avatar
                      :color="
                        incoTerm.destinationParty &&
                        incoTerm.destinationParty.logo
                          ? 'white'
                          : 'secondary'
                      "
                      size="32"
                    >
                      <v-img
                        v-if="
                          incoTerm.destinationParty &&
                          incoTerm.destinationParty.logo
                        "
                        :src="incoTerm.destinationParty.logo"
                        contain
                      />
                      <h3 v-else-if="incoTerm.destinationParty">
                        {{ incoTerm.destinationParty.name.charAt(0) }}
                      </h3>
                      <h3 v-else>-</h3>
                    </v-avatar>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="incoTerm.destinationParty">{{
                      incoTerm.destinationParty.name
                    }}</v-list-item-title>
                    <v-list-item-title v-else> - </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 11px"
                      >Destination Charges</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="chargeModal" width="500px" persistent>
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title> Search Organisatons </v-toolbar-title>
          <v-spacer />
          <v-btn icon color="redPop" @click="chargeModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            prepend-inner-icon="search"
            placeholder="Search"
            v-model="search"
            hide-details
            clearable
          ></v-text-field>
          <v-list dense subheader style="max-height: 400px; overflow-y: auto">
            <template v-for="(item, index) in availableParties">
              <div v-if="item.type == 'SUBHEADER'" :key="index">
                <v-divider></v-divider>
                <v-subheader style="font-size: 14px">
                  {{ item.name }}
                </v-subheader>
                <v-divider></v-divider>
              </div>
              <v-list-item
                v-else
                :key="item.id"
                @click="setCharge(item, chargeType)"
              >
                <v-list-item-action>
                  <v-avatar
                    :color="item.logo ? 'white' : 'secondary'"
                    size="32"
                  >
                    <v-img v-if="item.logo" :src="item.logo" contain />
                    <h3 v-else>
                      {{ item.name.charAt(0) }}
                    </h3>
                  </v-avatar>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.type }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      type: Object,
      required: true,
    },
    customers: {
      type: Array,
    },
    contacts: {
      type: Array,
    },
  },
  data: () => ({
    chargeOptions: [],
    chargeModal: false,
    chargeType: null,
    paymentTypes: [
      // { key: "Additional", value: "Additional Charges" },
      { key: "OceanFreight", value: "Basic Freight" },
      // { key: "DestinationHaulage", value: "Destination Haulage Charges" },
      { key: "DestinationTerminalHandling", value: "Destination Port Charges" },
      // { key: "OriginTerminalHandling", value: "Origin Haulage Charges" },
      { key: "OriginHaulage", value: "Origin Port Charges" },
    ],
    paymentTerms: [
      { key: "Prepaid", value: "Pre-paid" },
      { key: "Collect", value: "Collect" },
    ],
    partyTypes: [
      {
        name: "Bank",
        value: "bank",
        allowLink: false,
        icon: "account_balance",
        conditionalFields: ["letterOfCredit"],
      },
      {
        name: "Shipper",
        value: "shipper",
        allowLink: true,
        icon: "directions_boat_filled",
        linkedValue: "onBehalfShipper",
      },
      {
        name: "Forwarder",
        value: "forwarder",
        allowLink: true,
        icon: "fast_forward",
        linkedValue: "onBehalfForwarder",
      },
      {
        name: "Buyer",
        value: "buyer",
        allowLink: true,
        icon: "shopping_cart",
        linkedValue: "onBehalfBuyer",
      },
      {
        name: "Consignee",
        value: "consignee",
        allowLink: true,
        icon: "local_shipping",
        linkedValue: "onBehalfConsignee",
      },
      {
        name: "Courier",
        value: "courierParty",
        allowLink: false,
        icon: "local_post_office",
      },
      {
        name: "Notify",
        value: "firstNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "2nd Notify Party",
        value: "secondNotify",
        allowLink: false,
        icon: "notifications",
      },
      {
        name: "Customer",
        value: "customer",
      },
      {
        name: "Contract Party",
        value: "contractParty",
      },
    ],
    incoTerm: {},
    incoTerms: [],
    incoTermDialog: false,
    savingIncoTerm: false,
    search: null,
  }),
  async created() {
    this.incoTerms = await this.$API.getIncoTerms();
  },
  computed: {
    availableParties() {
      let result = [
        {
          type: "SUBHEADER",
          name: "Profile Parties",
        },
      ];
      for (let party of this.partyTypes) {
        if (this.profile[party.value]) {
          result.push({
            type: party.name,
            name: this.profile[party.value].name,
            id: this.profile[party.value].id,
            friendlyName:
              this.profile[party.value].friendlyName ??
              this.profile[party.value].alias,
            logo: this.profile[party.value].logo,
          });
        }
        // else if(party.value == 'customer' && this.customers.length > 0) {
        //   result.push({
        //     type: 'Customer',
        //     name: this.customers[0].name,
        //     id: this.customers[0].id,
        //     friendlyName: this.customers[0].friendlyName ?? this.customers[0].alias,
        //     logo: this.customers[0].logo,

        //   })
        // }
      }
      result.push({
        type: "SUBHEADER",
        name: "Other Parties",
      });
      result = [
        ...result,
        ...this.contacts
          .map((x) => x.relatedOrganisation)
          .filter((x) => !result.some((y) => y.id == x.id)),
      ];
      if (this.search) {
        result = result.filter(
          (x) =>
            x.type != "SUBHEADER" &&
            x.name.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return result;
    },
  },
  methods: {
    addPayment() {
      this.incoTerm = {
        index: this.profile.consigneeProfileIncoTerms.length,
        consigneeProfileShippingContracts: [],
        paymentTerm: "Collect",
        originTerm: "Collect",
        destinationTerm: "Collect",
        freightTerm: "Collect",
        incoTerm: "FOB",
      };
      // this.setParties();
      this.incoTermDialog = true;
    },
    editPayment(item) {
      this.incoTerm = item;
      this.incoTerm.index =
        this.profile.consigneeProfileIncoTerms.indexOf(item);
      // this.setParties();
      this.incoTermDialog = true;
    },
    editChargeParty(type) {
      this.chargeType = type;
      this.chargeModal = true;
    },
    formatChargeOrganisation(type) {
      if (type) {
        let find = this.chargeOptions.find((x) => x.value == type);
        return find ? find.name : null;
      }
      return null;
    },
    formatChargeType(type) {
      let party = this.partyTypes.find((x) => x.value == type);
      return party ? party.name : null;
    },
    setParties() {
      for (let party of this.partyTypes) {
        if (party.value == "customer" && this.customers.length > 0) {
          let customer = this.customers[0];
          let obj = {
            id: customer.id,
            name: customer.name,
            friendlyName: customer.friendlyName,
            logo: customer.logo,
            value: party.value,
            type: party.name,
          };
          this.chargeOptions.push(obj);
        } else if (party.value == "contractParty") {
          if (
            this.incoTerm &&
            this.incoTerm.contractOwner &&
            this.profile[this.incoTerm.contractOwner]
          ) {
            this.chargeOptions[i].friendlyName =
              this.profile[this.incoTerm.contractOwner].name;
            let obj = {
              id: this.profile[this.incoTerm.contractOwner].id,
              name: this.profile[this.incoTerm.contractOwner].name,
              friendlyName:
                this.profile[this.incoTerm.contractOwner].alias ??
                this.profile[this.incoTerm.contractOwner].friendlyName,
              logo: this.profile[this.incoTerm.contractOwner].logo,
              value: party.value,
              type: party.name,
            };
            this.chargeOptions.push(obj);
          }
        } else if (this.profile[party.value]) {
          let obj = {
            id: this.profile[party.value].id,
            name: this.profile[party.value].name,
            friendlyName:
              this.profile[party.value].alias ??
              this.profile[party.value].friendlyName,
            logo: this.profile[party.value].logo,
            value: party.value,
            type: party.name,
          };
          this.chargeOptions.push(obj);
        }
      }
      console.log(this.chargeOptions);
    },
    async removeProfile(item) {
      this.$confirm(
        "Are you sure you want to remove this deal term from the profile?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if (item.id) {
            await this.$API.updateConsigneeProfileIncoTerm({
              id: item.id,
              isActive: false,
              isDeleted: true,
            });
          }
          this.profile.consigneeProfileIncoTerms.splice(
            this.profile.consigneeProfileIncoTerms.indexOf(item),
            1
          );
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch((e) => {
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },

    async saveIncoTerm() {
      this.savingIncoTerm = true;
      if (this.incoTerm.id) {
        await this.$API.updateConsigneeProfileIncoTerm(this.incoTerm);
        this.profile.consigneeProfileIncoTerms[
          this.profile.consigneeProfileIncoTerms.findIndex(
            (x) => x.id == this.incoTerm.id
          )
        ] = this.incoTerm;
      } else {
        if (this.profile.id) {
          this.incoTerm.consigneeProfileId = this.profile.id;
          let createdIncoTerm = await this.$API.createConsigneeProfileIncoTerm(
            this.incoTerm
          );
          this.incoTerm.id = createdIncoTerm.id;
        }
        if (this.profile.consigneeProfileIncoTerms[this.incoTerm.index]) {
          this.profile.consigneeProfileIncoTerms[this.incoTerm.index] =
            this.incoTerm;
        } else {
          this.profile.consigneeProfileIncoTerms.push(this.incoTerm);
        }
      }
      this.incoTermDialog = false;
      this.incoTerm = {};
      this.savingIncoTerm = false;
    },
    setCharge(item, type) {
      this.incoTerm[type + "PartyId"] = item.id;
      this.incoTerm[type + "Party"] = item;
      this.chargeModal = false;
      this.search = null;
    },
  },
};
</script>
<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}
</style>